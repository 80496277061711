<template>
    <div class="error"></div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style lang="stylus" scoped>
.error
  width 100%
  height 100%
  background url("./../../assets/images/default/404.png") center no-repeat
  background-size 70%
</style>
